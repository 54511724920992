.description-container {
  padding: 1em 4em;
  white-space: pre-line;

  &.description-container-mobile {
    padding: 1em 2em !important;
  } 

  .logo-equipment{
    margin-right: 0.5rem;
    width: 1em;
    max-height: 1em;
  }
}

