.footer0-wrapper {
  background-color: @template-bg-color;
  height: 80px;
  overflow: hidden;
  .footer0 {
    height: 100%;
    padding: 0 24px;
    line-height: 80px;
    text-align: center;
    color: @template-footer-text-color;
    position: relative;

    .footer-text-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo-small-footer {
        margin-right: 1rem;
      }

      .logo-container {
        margin-left: 1rem;
        .svg-footer {
          width: 24px;
          cursor: pointer;
          height: auto;
          max-height: 24px;
          margin: -0.5em 1rem;
        }
        .facebook {
          fill: #3b5998;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .footer0-wrapper {
    .footer0 {
      font-size: 12px;
      &.home-page {
        padding: 0;
      }
      >div {
        width: 90%;
        margin: auto;
      }
    }
  }
}
