.exposition-card-wrapper-mobile {
  padding: 2em;
}

.exposition-card-wrapper {
  padding: 2em 4em;
}

.ant-card-body {
  padding: 12px !important;
}

.exposition-col {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;

  .ant-card-meta-title {
    white-space: break-spaces !important;
  }

  .ant-card-meta-title  {
    white-space: normal;
  }
}
